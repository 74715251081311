<template>
  <div>
    <!-- 员工编辑 -->
    <el-dialog
      width="30%"
      title="编辑员工信息"
      :visible.sync="usereidtVisible"
      append-to-body
      @close="editemployeeVisibleClose"
      :before-close="editemployeeVisibleClose"
      custom-class="editemployee-dialog"
      top="10%"
    >
      <el-form
        class="editemployee-container"
        :model="EditemployeeList"
        ref="editEmployeeFormRef"
        :rules="editEmployeeFormRules"
      >
        <div class="editemployee-gridcontainer">
          <el-form-item class="editemployeeItem" label="账号:" prop="account">
            <!-- <span class="addemployee-title">账户:</span> -->
            <el-input
              v-model="EditemployeeList.account"
              placeholder="请输入内容"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item class="editemployeeItem" label="密码:" prop="password">
            
            <el-input
              v-model="EditemployeeList.password"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="editemployeeItem"
            label="确认密码:"
            prop="confirmPassword"
          >
           
            <el-input
              v-model="EditemployeeList.confirmPassword"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item> -->
          <!-- 姓名 -->
          <el-form-item class="editemployeeItem" label="姓名:" prop="real_name">
            <!-- <span class="addemployee-title">姓名:</span> -->
            <el-input
              v-model="EditemployeeList.real_name"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 联系方式 -->
          <el-form-item
            class="editemployeeItem"
            label="联系方式:"
            prop="telephone"
          >
            <!-- <span class="addemployee-title">联系方式:</span> -->
            <el-input
              v-model="EditemployeeList.telephone"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 性别 -->
          <el-form-item class="editemployeeItem" label="性别:" prop="gender">
            <!-- <span class="addemployee-title">性别:</span> -->
            <el-select v-model="EditemployeeList.gender" placeholder="请选择">
              <el-option
                v-for="item in genderoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 学历 -->
          <el-form-item class="editemployeeItem" label="学历:" prop="education">
            <!-- <span class="addemployee-title">学历:</span> -->
            <el-select
              v-model="EditemployeeList.education"
              placeholder="请选择"
            >
              <el-option
                v-for="item in educationoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 邮箱 -->
          <el-form-item class="editemployeeItem" label="邮箱:" prop="email">
            <!-- <span class="addemployee-title">邮箱:</span> -->
            <el-input
              v-model="EditemployeeList.email"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item class="editemployeeItem" label="状态:" prop="status">
            <!-- <span class="addemployee-title">状态:</span> -->
            <el-select v-model="EditemployeeList.status" placeholder="请选择">
              <el-option
                v-for="item in stateoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 片区 -->
          <el-form-item class="editemployeeItem" label="片区:" prop="area_id">
            <!-- <span class="addemployee-title">片区:</span> -->
            <el-select v-model="EditemployeeList.area_id" placeholder="请选择">
              <el-option
                v-for="item in areaoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 工号 -->
          <el-form-item
            class="editemployeeItem"
            label="工号:"
            prop="job_number"
          >
            <!-- <span class="addemployee-title">工号:</span> -->
            <el-input
              v-model="EditemployeeList.job_number"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 角色 -->
          <el-form-item class="editemployeeItem" label="角色:" prop="role_id">
            <!-- <span class="addemployee-title">角色:</span> -->
            <el-select v-model="EditemployeeList.role_id" placeholder="请选择">
              <el-option
                v-for="item in roleoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editemployeeVisibleClose">取消</el-button>
        <el-button @click="submitedit" class="resBtn">更改</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  inject: ['getemployeeList'],
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (value == '' || regEmail.test(value)) {
        return cb()
      }
      //返回一个错误提示
      cb(new Error('请输入合法的邮箱'))
    }
    //验证手机号码的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[34578]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      //返回一个错误提示
      cb(new Error('请输入合法的手机号码'))
    }

    return {
      EditemployeeList: {
        real_name: '',
        telephone: '',
        gender: '',
        education: '',
        email: '',
        status: '',
        area_id: '',
        job_number: '',
        // job_id: '',职位
        role_id: '',
        admin_id: '',
        // superior_id: '',上级
        account: '',//账户
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      queryInfo: {
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      input: '',
      name: '',

      //   性别
      genderoptions: [{
        value: 1,
        label: '男'
      }, {
        value: 2,
        label: '女'
      },
      {
        value: 0,
        label: '保密'
      }],
      // 学历
      educationoptions: [
        {
          value: '1',
          label: '大学本科'
        }, {
          value: '2',
          label: '专科'
        },
        {
          value: '3',
          label: '博士'
        },
        {
          value: '4',
          label: '硕士'
        }
      ],
      stateoptions: [
        {
          value: 1,
          label: '在职'
        },
        {
          value: 2,
          label: '离职'
        }
      ],
      areaoptions: [],
      roleoptions: [],
      positionoptions: [
        {
          value: '1',
          label: '会计'
        },
        {
          value: '2',
          label: '税务会计'
        }
      ],
      // Superiorlist: [],
      dialogImageUrl: '',
      imgdialogVisible: false,
      disabled: false,
      value1: '',
      editEmployeeFormRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          // {
          //   min: 3,
          //   max: 15,
          //   message: '账号在3~15个字符之间',
          //   trigger: 'blur'
          // }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '密码在6~15个字符之间',
            trigger: 'blur'
          },
          // { validator: checkPwd, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '密码在6~15个字符之间',
            trigger: 'blur'
          },
          // { validator: checkPwd2, trigger: 'blur' }
        ],
        real_name: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '姓名在2~10个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: false, validator: checkEmail, message: '邮箱格式不正确，请重新输入', trigger: 'blur' }
        ],
        telephone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkMobile, message: '手机号码不正确，请重新输入', trigger: 'blur' }
        ],
        area_id: [
          { required: true, message: '请选择片区', trigger: 'blur' },
        ],
        role_id: [
          { required: true, message: '请选择角色', trigger: 'blur' },
        ]
      }
    }
  },
  props: {
    usereidtVisible: {
      type: Boolean,
      default: false
    },
    editdata: {
      type: Object,
    }
  },
  created() {

  },
  mounted() {
    this.getRole()
    this.getAreaList()
    this.getEditemployeeList()
  },
  watch: {
    editdata: function (newVal, oldVal) {
      this.EditemployeeList.admin_id = newVal.id
      console.log(oldVal);
      newVal && this.getEditemployeeList();
    }
  },
  methods: {
    async getEditemployeeList() {
      const res = await this.$http.post('Admin/personEditList', this.EditemployeeList)
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 1)
        // return this.$message.error(res.data.msg)
        return this.$message({
          showClose: true,
          message: res.data.msg,
          type: 'error',
          // offset: 500
        });
      this.EditemployeeList.real_name = res.data.data.real_name,
        this.EditemployeeList.telephone = res.data.data.telephone,
        this.EditemployeeList.gender = res.data.data.gender,
        this.EditemployeeList.education = res.data.data.education,
        this.EditemployeeList.email = res.data.data.email,
        this.EditemployeeList.status = res.data.data.status,
        this.EditemployeeList.area_id = res.data.data.area_id,
        this.EditemployeeList.job_number = res.data.data.job_number,
        this.EditemployeeList.role_id = res.data.data.role_id,
        this.EditemployeeList.account = res.data.data.account

    },
    async submitedit() {
      const res = await this.$http.post('Admin/personEdit', this.EditemployeeList)
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 1)
        // return this.$message.error(res.data.msg)
        return this.$message({
          showClose: true,
          message: res.data.msg,
          type: 'error',
          // offset: 500
        });
      this.$message.success(res.data.msg)
      this.getemployeeList()
      this.editemployeeVisibleClose()
    },
    //获取片区
    async getAreaList() {
      const res = await this.$http.post("Area/getAreaList", this.queryInfo)
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.areaoptions = res.data.data
      console.log(this.areaoptions);

    },

    //获取角色
    async getRole() {
      const res = await this.$http.get('admin/getRole', { params: this.queryInfo })
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200)
        return this.$message.error(res.data.msg)
      this.roleoptions = res.data.data

    },
    editemployeeVisibleClose() {
      //   console.log('内置dialog被关闭')
      this.$emit('usereidtVisibleClose')
    }
  }
}
</script>

<style lang="scss">
.editemployee-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 400px;
    overflow: auto;
    padding: 50px;
    .editemployee-gridcontainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
    .editemployeeItem {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-form-item__label {
        width: 100px;
        text-align: left;
      }
      .el-input,
      .el-select {
        flex: 1;
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>