<template>
  <div>
    <el-dialog
      width="30%"
      title="创建员工信息"
      :visible.sync="addemployeeVisible"
      append-to-body
      @close="addemployeeVisibleClose"
      :before-close="addemployeeVisibleClose"
      custom-class="addemployee-dialog"
      top="10%"
    >
      <el-form
        class="addemployee-container"
        :model="addemployeeList"
        ref="addEmployeeFormRef"
        :rules="addEmployeeFormRules"
      >
        <div class="addemployee-gridcontainer">
          <el-form-item class="addemployeeItem" label="账号:" prop="account">
            <!-- <span class="addemployee-title">账户:</span> -->
            <el-input
              v-model="addemployeeList.account"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 姓名 -->
          <el-form-item class="addemployeeItem" label="姓名:" prop="real_name">
            <!-- <span class="addemployee-title">姓名:</span> -->
            <el-input
              v-model="addemployeeList.real_name"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item class="addemployeeItem" label="密码:" prop="password">
            <!-- <span class="addemployee-title">密码:</span> -->
            <el-input
              v-model="addemployeeList.password"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="addemployeeItem"
            label="确认密码:"
            prop="confirmPassword"
          >
            <!-- <span class="addemployee-title">确认密码:</span> -->
            <el-input
              v-model="addemployeeList.confirmPassword"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 联系方式 -->
          <el-form-item
            class="addemployeeItem"
            label="联系方式:"
            prop="telephone"
          >
            <!-- <span class="addemployee-title">联系方式:</span> -->
            <el-input
              v-model="addemployeeList.telephone"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 性别 -->
          <el-form-item class="addemployeeItem" label="性别:" prop="gender">
            <!-- <span class="addemployee-title">性别:</span> -->
            <el-select v-model="addemployeeList.gender" placeholder="请选择">
              <el-option
                v-for="item in genderoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 学历 -->
          <el-form-item class="addemployeeItem" label="学历:" prop="education">
            <!-- <span class="addemployee-title">学历:</span> -->
            <el-select v-model="addemployeeList.education" placeholder="请选择">
              <el-option
                v-for="item in educationoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 邮箱 -->
          <el-form-item class="addemployeeItem" label="邮箱:" prop="email">
            <!-- <span class="addemployee-title">邮箱:</span> -->
            <el-input
              v-model="addemployeeList.email"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 状态 -->
          <el-form-item class="addemployeeItem" label="状态:" prop="status">
            <!-- <span class="addemployee-title">状态:</span> -->
            <el-select v-model="addemployeeList.status" placeholder="请选择">
              <el-option
                v-for="item in stateoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 片区 -->
          <el-form-item class="addemployeeItem" label="片区:" prop="area_id">
            <!-- <span class="addemployee-title">片区:</span> -->
            <el-select v-model="addemployeeList.area_id" placeholder="请选择">
              <el-option
                v-for="item in areaoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 工号 -->
          <el-form-item class="addemployeeItem" label="工号:" prop="job_number">
            <!-- <span class="addemployee-title">工号:</span> -->
            <el-input
              v-model="addemployeeList.job_number"
              placeholder="请输入内容"
            ></el-input>
          </el-form-item>
          <!-- 职位 -->
          <!-- <div class="addemployeeItem">
            <span class="addemployee-title">职位:</span>
            <el-select v-model="addemployeeList.job_id" placeholder="请选择">
              <el-option
                v-for="item in positionoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <!-- 角色 -->
          <el-form-item class="addemployeeItem" label="角色:" prop="role_id">
            <!-- <span class="addemployee-title">角色:</span> -->
            <el-select v-model="addemployeeList.role_id" placeholder="请选择">
              <el-option
                v-for="item in roleoptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <!-- 上级 -->
          <!-- <div class="addemployeeItem">
            <span class="addemployee-title">上级:</span>
            <el-select
              v-model="addemployeeList.superior_id"
              placeholder="请选择"
            >
              <el-option
                v-for="item in Superiorlist"
                :key="item.id"
                :label="item.real_name + '(' + item.role_name + ')'"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div> -->
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addemployeeVisibleClose">取消</el-button>
        <el-button @click="addEmployee" class="resBtn">创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    // 验证邮箱的规则
    var checkEmail = (rule, value, cb) => {
      const regEmail = /^\w+@\w+(\.\w+)+$/
      if (value == '' || regEmail.test(value)) {
        return cb()
      }
      //返回一个错误提示
      cb(new Error('请输入合法的邮箱'))
    }
    //验证手机号码的规则
    var checkMobile = (rule, value, cb) => {
      const regMobile = /^1[345789]\d{9}$/
      if (regMobile.test(value)) {
        return cb()
      }
      //返回一个错误提示
      cb(new Error('请输入合法的手机号码'))
    }

    return {
      addemployeeList: {
        real_name: '',
        telephone: '',
        gender: '',
        education: '',
        email: '',
        status: '1',
        area_id: '',
        job_number: '',
        // job_id: '',职位
        role_id: '',

        // superior_id: '',上级
        account: '',//账户
        password: '',//密码
        confirmPassword: '',//确认密码
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      queryInfo: {
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      input: '',
      name: '',

      //   性别
      genderoptions: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      },
      {
        value: '0',
        label: '保密'
      }],
      // 学历
      educationoptions: [
        {
          value: '1',
          label: '大学本科'
        }, {
          value: '2',
          label: '专科'
        },
        {
          value: '3',
          label: '博士'
        },
        {
          value: '4',
          label: '硕士'
        }
      ],
      stateoptions: [
        {
          value: '1',
          label: '在职'
        },
        {
          value: '2',
          label: '离职'
        }
      ],
      areaoptions: [],
      roleoptions: [],
      positionoptions: [
        {
          value: '1',
          label: '会计'
        },
        {
          value: '2',
          label: '税务会计'
        }
      ],
      // Superiorlist: [],
      dialogImageUrl: '',
      imgdialogVisible: false,
      disabled: false,
      value1: '',
      addEmployeeFormRules: {
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          // {
          //   min: 3,
          //   max: 15,
          //   message: '账号在3~15个字符之间',
          //   trigger: 'blur'
          // }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '密码在6~15个字符之间',
            trigger: 'blur'
          },
          // { validator: checkPwd, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 15,
            message: '密码在6~15个字符之间',
            trigger: 'blur'
          },
          // { validator: checkPwd2, trigger: 'blur' }
        ],
        real_name: [
          { required: true, message: '请输入员工姓名', trigger: 'blur' },
          {
            min: 2,
            max: 10,
            message: '姓名在2~10个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          { required: false, validator: checkEmail, message: '邮箱格式不正确，请重新输入', trigger: 'blur' }
        ],
        telephone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { validator: checkMobile, message: '手机号码不正确，请重新输入', trigger: 'blur' }
        ],
        area_id: [
          { required: true, message: '请选择片区', trigger: 'blur' },
        ],
        role_id: [
          { required: true, message: '请选择角色', trigger: 'blur' },
        ]
      }
    }
  },
  props: {
    addemployeeVisible: {
      type: Boolean,
      default: false
    },
    getemployeeList: {
      type: Function
    }
  },
  mounted() {
    this.getRole()
    this.getAreaList()
  },
  methods: {
    addEmployee() {
      this.$refs.addEmployeeFormRef.validate(async valid => {
        if (!valid) return this.$message({
          showClose: true,
          message: "请填写完整用户信息",
          type: 'error',
          // offset: 500
        });
        const res = await this.$http.post('Admin/add', this.addemployeeList)
        console.log(res);
        if (res.data.code === 10001) {
          this.$message.error(res.data.msg);
          this.$router.push('./admin')
        }
        if (res.data.code !== 200)
          // return this.$message.error(res.data.msg)
          return this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'error',
            // offset: 500
          });
        this.getemployeeList()
        this.addemployeeVisibleClose()
      })
    },
    //获取片区
    async getAreaList() {
      const res = await this.$http.post("Area/getAreaList", this.queryInfo)
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.areaoptions = res.data.data
      console.log(this.areaoptions);

    },
    // async addEmployee() {
    //   const res = await this.$http.post('Admin/add', this.addemployeeList)
    //   console.log(res);
    //   if (res.data.code !== 200)
    //     // return this.$message.error(res.data.msg)
    //     return this.$message({
    //       showClose: true,
    //       message: res.data.msg,
    //       type: 'error',
    //       // offset: 500
    //     });
    //   this.getemployeeList()
    //   this.addemployeeVisibleClose()
    // },
    //获取角色
    async getRole() {
      const res = await this.$http.get('admin/getRole', { params: this.queryInfo })
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push('./admin')
      }
      if (res.data.code !== 200)
        return this.$message.error(res.data.msg)
      this.roleoptions = res.data.data

    },
    addemployeeVisibleClose() {
      console.log('内置dialog被关闭')
      this.$emit('addemployeeVisibleClose')
      this.$refs.addEmployeeFormRef.resetFields()
    }
  }
}
</script>

<style lang="scss">
.addemployee-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 400px;
    overflow: auto;
    padding: 50px;
    .addemployee-gridcontainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
    .addemployeeItem {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .el-form-item__label {
        width: 100px;
        text-align: left;
      }
      .el-input,
      .el-select {
        flex: 1;
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>