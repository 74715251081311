<template>
  <div class="employeemanagementtable-container">
    <el-table
      :data="employeeList"
      style="width: 100%"
      class="employeemanagementtable"
      stripe
    >
      <!-- 序号 -->
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="80"
        fixed
      >
      </el-table-column>
      <!-- 片区 -->
      <el-table-column
        prop="area_name"
        label="片区"
        align="center"
        :filters="areaList"
        :filter-method="filterTag"
        filter-placement="bottom-end"
      >
      </el-table-column>
      <!-- 账号 -->
      <el-table-column prop="account" label="账号" align="center">
      </el-table-column>
      <!-- 姓名 -->
      <el-table-column prop="real_name" label="姓名" align="center">
      </el-table-column>
      <!-- 联系方式 -->
      <el-table-column prop="telephone" label="联系方式" align="center">
      </el-table-column>
      <!-- 邮箱 -->
      <el-table-column prop="email" label="邮箱" align="center">
      </el-table-column>
      <!-- 角色 -->
      <el-table-column prop="role.name" label="角色" align="center">
      </el-table-column>
      <!-- 状态 -->
      <el-table-column
        prop="status"
        label="状态"
        width="100"
        align="center"
        :filters="[
          { text: '在职', value: 1 },
          { text: '冻结', value: 2 },
        ]"
        :filter-method="filterTagStatus"
        filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.status === 1 ? 'success' : 'primary'"
            disable-transitions
            >{{ scope.row.status == "1" ? "在职" : "冻结" }}</el-tag
          >
        </template>
      </el-table-column>
      <!-- 性别 -->
      <el-table-column prop="gender" label="性别" align="center">
        <template slot-scope="scope">
          {{
            scope.row.gender == "1"
              ? "男"
              : scope.row.gender == "2"
              ? "女"
              : "保密"
          }}
        </template>
      </el-table-column>
      <!-- 工号 -->
      <el-table-column prop="job_number" label="工号" align="center">
      </el-table-column>
      <!-- 学历 -->
      <el-table-column prop="education" label="学历" align="center">
        <template slot-scope="scope">
          {{
            scope.row.education == "1"
              ? "大学本科"
              : scope.row.education == "2"
              ? "专科"
              : scope.row.education == "3"
              ? "博士"
              : scope.row.education == "4"
              ? "硕士"
              : "保密"
          }}
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button
            @click="openEditDialog(scope.row)"
            type="button"
            class="seebtn"
            >编辑</el-button
          >
          <el-button
            @click="freezeDialogClick(scope.row)"
            :type="scope.row.status == 1 ? `button` : 'info'"
            :disabled="!(scope.row.status == 1)"
            class="quitbtn"
            >{{ scope.row.status == 1 ? `冻结` : `已冻结` }}</el-button
          >
          <el-button
            @click="openEditPwddialog(scope.row)"
            type="button"
            class="detailsbtn"
            >改密</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <editemployeeCom
      :usereidtVisible="usereidtVisible"
      :editdata="editdata"
      @usereidtVisibleClose="usereidtVisibleClose"
    ></editemployeeCom>

    <!-- 冻结确认对话框 -->
    <el-dialog title="警告" :visible.sync="freezeDialog" width="30%">
      <div>是否确定冻结改员工</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="freezeDialog = false">取 消</el-button>
        <el-button type="primary" @click="requestFreezeDialog">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改密码对话框 -->
    <el-dialog
      title="修改密码"
      :visible.sync="editemployeePassworddialogVisible"
      width="30%"
      :before-close="handleClose"
      @close="handleClose"
      custom-class="passwordDialog"
    >
      <el-form
        :model="editemployeePassword"
        :rules="editemployeePasswordRules"
        ref="editemployeePassword"
        label-width="80px"
      >
        <el-form-item label="新密码:" label-position="left">
          <el-input
            type="password"
            v-model="editemployeePassword.password"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="确认密码:" label-position="left">
          <el-input
            type="password"
            v-model="editemployeePassword.password_confirm"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="EditPassword('editemployeePasswordForm')"
          >提交</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import editemployeeCom from './editemployeeCom.vue'
export default {
  components: {
    editemployeeCom
  },
  props: {
    employeeList: {
      type: Array,
    },
  },
  inject: ['getemployeeList'],

  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      taskdetailsVisible: false,

      // usereidtVisible: false,
      editdata: {},

      usereidtVisible: false,

      // 冻结员工对话框
      freezeDialog: false,
      editemployeePassworddialogVisible: false,//修改密码对话框
      editemployeePassword: {
        admin_id: '',
        password: '',//新密码
        // password_confirm: '',//确认密码
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token
      },
      editemployeePasswordRules: {
        password: [
          // { required: true, message: "新密码不能为空", trigger: "blur" },
          // { min: 6, max: 12, message: "长度在8到12个字符", trigger: "blur" },
          { validator: validatePass, trigger: 'blur' }
        ],
        password_confirm: [
          // { required: true, message: "确认密码不能为空", trigger: "blur" },
          // { required: true, validator: validatePass2, trigger: "blur" }
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      // 当前数据
      currentData: {},

      rowdata: {},

      areaList: [
        { text: "南宁总部", value: 1 },
        { text: "南宁高新", value: 2 },
        { text: "南宁青秀", value: 3 },
        { text: "南宁江南", value: 4 },
        { text: "玉林", value: 5 },
        { text: "柳州", value: 6 },
        { text: "桂林", value: 7 },
        { text: "百色", value: 8 },
        { text: "来宾", value: 9 },
        { text: "梧州", value: 10 },
        { text: "钦州", value: 11 },
      ],
    };
  },
  methods: {
    openEditDialog(row) {
      console.log(row);
      this.editdata = row
      this.usereidtVisible = true
    },
    // 打开密码框
    openEditPwddialog(row) {
      console.log(row);
      // this.currentData = row;
      this.editemployeePassword.admin_id = row.id
      this.editemployeePassworddialogVisible = true

    },
    taskdetailsVisibleClose() {
      this.taskdetailsVisible = false;
    },
    usereidtVisibleClose() {
      this.usereidtVisible = false;
    },
    //片区
    filterTag(value, row) {
      return row.area_id === value;
    },
    //状态
    filterTagStatus(value, row) {
      return row.status === value;
    },
    // 打开冻结对话框
    freezeDialogClick(row) {
      console.log(row);
      this.currentData = row;
      this.freezeDialog = true;
    },
    handleClose() {
      this.editemployeePassworddialogVisible = false
      this.$refs.editemployeePasswordForm.resetFields()
    },
    // 发送冻结员工请求
    async requestFreezeDialog() {
      const { id: admin_id } = this.currentData;
      const { id: a_id, token } = this.$store.getters.adminlist;
      const { data } = await this.$http.post("/Admin/frozenPerson", {
        admin_id,
        a_id,
        token,
      });
      if (data.code != 1) return this.$message.error(data.msg);
      this.$message.success(data.msg);
      this.freezeDialog = false;
      this.$emit("updateEmployeeList");
    },
    async EditPassword() {
      const res = await this.$http.post('Admin/pass_Person', this.editemployeePassword)
      console.log(res);
      if (res.data.code !== 200)
        return this.$message.error(res.data.msg)
      this.$message.success(res.data.msg)
      this.handleClose()
    }
  },
};
</script>

<style lang="scss">
.employeemanagementtable-container {
  max-height: 1600px;
  overflow: hidden;
  .el-icon-arrow-down {
    color: #ffffff;
  }
  .employeemanagementtable {
    thead {
      th {
        color: #ffffff;
        background-color: $index-buttonPor-color;
      }
    }
    .seebtn {
      background-color: $index-button-color;
      color: #ffffff;
    }
    .quitbtn {
      background-color: $index-buttonerr-color;
      color: #ffffff;
    }
    .detailsbtn {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
    .withdrawbtn {
      background-color: $index-buttonerr-color;
      color: #ffffff;
    }
  }
}
</style>
