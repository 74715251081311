<template>
  <div class="employee-management-container">
    <!-- 增删改查 -->
    <div class="managementBox">
      <el-button
        icon="el-icon-circle-plus-outline"
        class="addBtn"
        @click="addUserFunc"
        >添加</el-button
      >
      <el-button class="select" @click="getemployeeList">搜索</el-button>
      <el-button @click="resetBtn">重置</el-button>
      <el-input
        v-model="queryInfo.real_name"
        placeholder="请输入员工姓名"
        suffix-icon="el-icon-search"
        @keyup.enter.native="getemployeeList"
      ></el-input>
    </div>

    <div class="table-container">
      <employeemanagementtable-com
        @updateEmployeeList="updateEmployeeList"
        :employeeList="employeeList"
      ></employeemanagementtable-com>
    </div>
    <pagination-com
      :getemployeeList="getemployeeList"
      @pagesize="pagesizelimit"
      @pagecurrent="pagecurrent"
      :total="total"
    ></pagination-com>
    <addemployee-com
      :addemployeeVisible="addemployeeVisible"
      @addemployeeVisibleClose="addemployeeVisibleClose"
      :getemployeeList="getemployeeList"
    ></addemployee-com>
  </div>
</template>

<script>
import employeemanagementtableCom from "../components/employee-management/employeemanagementtableCom.vue";
import addemployeeCom from "../components/employee-management/addemployeeCom.vue";
import paginationCom from "../components/paginationCom.vue";

export default {
  components: {
    employeemanagementtableCom,
    addemployeeCom,
    paginationCom,
  },
  //传给子孙组件的方法
  provide() {
    return {
      getemployeeList: this.getemployeeList
    }
  },
  data() {
    return {
      addemployeeVisible: false,
      // 客户名称或手机号
      customer_phone: "",
      // 联系人
      contacts: "",
      // 地址
      address: "",
      // 所属客服
      customer_service: "",
      value: "",

      currentPage3: 1,
      queryInfo: {
        page: 1,
        limit: 20,
        real_name: "",
        a_id: this.$store.state.adminlist.id,
        token: this.$store.state.adminlist.token,
      },
      total: 0,
      employeeList: [],
    };
  },
  mounted() {
    this.getemployeeList();
  },
  methods: {
    async getemployeeList() {
      const res = await this.$http.get("Admin/index", {
        params: this.queryInfo,
      });
      console.log(res);
      if (res.data.code === 10001) {
        this.$message.error(res.data.msg);
        this.$router.push("./admin");
      }
      if (res.data.code !== 200) return this.$message.error(res.data.msg);
      this.employeeList = res.data.data;
      this.total = res.data.count;
    },
    resetBtn() {
      this.queryInfo.real_name = "";
      this.getemployeeList();
    },
    addUserFunc() {
      this.addemployeeVisible = true;
    },
    addemployeeVisibleClose() {
      this.addemployeeVisible = false;
    },
    pagesizelimit(size) {
      this.queryInfo.limit = size;
      console.log(this.queryInfo.limit);
    },
    pagecurrent(current) {
      this.queryInfo.page = current;
      console.log(this.queryInfo.page);
    },
    // 更新员工列表
    updateEmployeeList() {
      this.getemployeeList();
    },
  },
};
</script>

<style lang="scss">
.employee-management-container {
  box-sizing: border-box;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;

  .selectContainer {
    display: grid;
    grid-row-gap: 20px;
    // grid-column-gap: 25px;
    grid-template-columns: repeat(auto-fill, 400px);
    justify-content: space-between;
    .el-input__inner {
      display: block;
      width: 400px !important;
    }
    .el-date-editor--datetimerange {
      // grid-column-start:项目的起始列，
      // grid-column-start: 2;

      // grid-column-end:项目结束列，
      // grid-column-end: 4;

      // 元素的左边框距离右边框跨越2个网格
      grid-column-end: span 2;

      width: 100% !important;
      line-height: 0;
      .el-range-separator {
        color: $index-hui-color;
      }
      .el-range-input {
        width: 40%;
      }
    }
  }
  .managementBox {
    margin-top: 10px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    .el-input {
      width: 300px;
      margin-left: 10px;
    }
    .el-button {
      width: 108px;
    }
    .select {
      background-color: $index-button-color;
      color: #ffffff;
    }
  }

  .addBtn {
    width: 108px;
    background-color: #8674eb;
    color: #ffffff;
  }

  .table-container {
    max-height: 1600px;
    overflow: hidden;
    margin-top: 30px;
  }
  .pagination-container {
    margin-top: 30px;
    height: 40px;
    .el-pagination {
      height: 100%;
      box-sizing: border-box;
      color: #666666;
      .el-pager li {
        padding: 0 25px;
        font-size: 16px !important;
        height: 100%;
        line-height: 40px;
      }
      .el-pager li.active {
        color: #409eff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 30px;
        height: 40px;
        text-align: center;
      }
      .btn-prev {
        border-radius: 10px 0 0 10px;
      }
      .btn-next {
        border-radius: 0px 10px 10px 0px;
      }
      .el-pagination__jump {
        .el-input {
          margin: 0 20px;
          font-size: 16px;
        }
      }
      button,
      span:not([class*="suffix"]) {
        height: 40px;
        line-height: 40px;
        font-size: 16px !important;
      }
    }
    .el-icon-arrow-left:before {
      content: "上一页";
      font-size: 16px;
    }
    .el-icon-arrow-right:before {
      content: "下一页";
      font-size: 16px;
    }
  }
}
</style>
